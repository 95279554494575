import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { setLanguage, t } from '../translation.js';

import CustomBtn from '../assets/img/custom-btn.png';

import ArkPayDemo1 from '../assets/img/arkpay-demo-1.jpg';
import ArkPayDemo2 from '../assets/img/arkpay-demo-2.jpg';
import ArkPayDemo3 from '../assets/img/arkpay-demo-3.jpg';

const ShopPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			// getUserUSDTBalance();
			// checkTokenAllowance();
		}
	};

	useEffect(() => {
		getData();
		fetchUserData();
	}, []);

	return (
		<>
		<div className="main-content market-bg">
			<div className="container pt-5">
				
				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('shop-title')}</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						{/* <div className="market-box"> */}
							<div className="wallet-balance-box text-left">
								<p className="text-white mb-0"><span className="brand-yellow">SRC:</span> {userData && parseFloat(userData.src || 0).toFixed(2)}</p>
							</div>
						{/* </div> */}
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('arkpay-title')}</h3>
						<p className="text-gray mb-0">{t('coming-soon')}</p>
						<img className="w-100 mt-3" src={ArkPayDemo2}></img>
						<img className="w-100 mt-3" src={ArkPayDemo3}></img>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('shop-title2')}</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2">
						<div className="embed-box text-center">
							<iframe className="embed-shop" src="https://protal.pinai.tw/EKPMRW" title="description"></iframe>
							<button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
								window.open("https://protal.pinai.tw/EKPMRW", "_blank");
							}}>
								<img className="w-100" src={CustomBtn}></img>
								<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('expand-embed')}</strong></h5>
							</button>
							{/* <p className="text-gray mb-0">{t('coming-soon')}</p> */}
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);

};

export default ShopPage;