import { useState } from 'react';

export const useWithdrawal = () => {
    const [isWithdrawing, setIsWithdrawing] = useState(null);
    const [withdrawSuccess, setWithdrawSuccess] = useState(null);

    const submitWithdraw = async (curUserID, userWallet, withdrawAmount, withdrawType) => {
        setIsWithdrawing(true);

        const response = await fetch('https://api.ridechain.io/withdrawal/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: userWallet,
                withdrawal_amount: withdrawAmount,
                type: withdrawType
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setTimeout(() => setIsWithdrawing(false), 3000);
        }
        if (response.ok) {
            setWithdrawSuccess(true);
            setTimeout(() => setIsWithdrawing(false), 3000);
        }
    }

    return { submitWithdraw, setIsWithdrawing, isWithdrawing, withdrawSuccess, setWithdrawSuccess };
}