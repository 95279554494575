import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MiddleEllipsis from "react-middle-ellipsis";

import TeamBanner from '../assets/img/team-page-banner.jpg';

import CustomBtn from '../assets/img/custom-btn.png';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

import { setLanguage, t } from '../translation.js';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

const TeamPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log("Fetched User Data", json[0]);
				setUserData(json[0]);
				getUserDirectReferrals(json[0]);
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
		}
	};

	const [selectedLeader, setSelectedLeader] = useState(null);
	const [userDirectReferrals, setUserDirectReferrals] = useState(null);
    const getUserDirectReferrals = async (selectedUser) => {
		const response = await fetch('https://api.ridechain.io/user/referral/' + selectedUser.wallet);
        const json = await response.json();
        if (response.ok) {
			// setTimeout(() => setUserDirectReferrals(json), 2000);
            setUserDirectReferrals(json);
        }
		setSelectedLeader(selectedUser.wallet);
    }

	const [leaderData, setLeaderData] = useState(null);
	const fetchLeaderData = async () => {
		if (selectedLeader == null) {
			return;
		}
		const response = await fetch('https://api.ridechain.io/user/'+selectedLeader);
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log("Fetched Leader Data", json[0]);
				setLeaderData(json[0]);
				getUserDirectReferrals(json[0]);
			} else {
				console.log("Invalid leader data: More than 1 result.");
			}
		}
	};

	const { submitWithdraw, setIsWithdrawing, isWithdrawing, withdrawSuccess, setWithdrawSuccess } = useWithdrawal();

	const userWithdraw = async (_amount) => {
		await submitWithdraw(userData._id, blockchain.account, _amount, "Rank");
		setTimeout(() => fetchUserData(), 3000);
	};

	useEffect(() => {
		fetchUserData();
	}, []);

	useEffect(() => {
		console.log("Selected leader wallet:", selectedLeader);
		fetchLeaderData();
	}, [selectedLeader]);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 px-0">
						<img className="w-100" src={TeamBanner}></img>
					</div>
				</div>
			</div>
			<div className="main-content team-bg">
				<div className="container pt-5">
					<div className="row">

						<div className="col-12 text-center">
							<h3 className="brand-blue">{t('team-title')}</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									{userData && userData.rank == 0 ? <h5 className="text-white mb-0">{t('team-rank0')}</h5> : <></>}
									{userData && userData.rank == 1 ? <h5 className="brand-yellow mb-0">MINER</h5> : <></>}
									{userData && userData.rank == 2 ? <h5 className="brand-yellow mb-0">P1</h5> : <></>}
									{userData && userData.rank == 3 ? <h5 className="brand-yellow mb-0">P2</h5> : <></>}
									{userData && userData.rank == 4 ? <h5 className="brand-yellow mb-0">P3</h5> : <></>}
									{userData && userData.rank == 5 ? <h5 className="brand-yellow mb-0">P4</h5> : <></>}
									{userData && userData.rank == 6 ? <h5 className="brand-yellow mb-0">P5</h5> : <></>}
									{userData && userData.rank == 7 ? <h5 className="brand-yellow mb-0">P6</h5> : <></>}
									<p className="brand-yellow mt-2 mb-0"><strong>{t('team-unit')}: <span className="text-white">{userData && userData.total_team_count}</span></strong> </p>
									<p className="brand-yellow mt-2 mb-0"><strong>{t('team-total')}: <br></br> <span className="text-white">{userData && userData.total_team_sales ? parseFloat(userData.total_team_sales).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></strong> </p>
								</div>
							</div>
						</div>

						<div id="teamleader" className="col-12 text-center mt-5">
							<h3 className="brand-blue">{t('team-title2')}</h3>
						</div>

						<div className="col-12 text-center">
							<div className="data-container">
								<img className="w-100" src={BigDataBox}></img>
								<div className="data-text text-left">
									{leaderData && leaderData.rank == 0 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">{t('team-rank0')}</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 1 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">MINER</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 2 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P1</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 3 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P2</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 4 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P3</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 5 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P4</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 6 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P5</span></strong></h5> : <></>}
									{leaderData && leaderData.rank == 7 ? <h5 className="brand-yellow mb-0"><strong><span className="text-white mt-1 mb-0">P6</span></strong></h5> : <></>}
									<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('wallet-address')}: </strong><span className="text-white mt-1 mb-0" style={{wordBreak:"break-all"}}>{leaderData && "0x..."+leaderData.wallet.substring(36)}</span></p>
									<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('team-unit')}: <span className="text-white mt-1 mb-0" >{leaderData && leaderData.total_team_count}</span></strong></p>
									<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('team-p-total')}: <span className="brand-green mt-1 mb-0" >{leaderData && leaderData.total_team_sales ? parseFloat(leaderData.total_team_sales).toFixed(2) : parseFloat(0).toFixed(2)} USDT</span></strong></p>
								</div>
							</div>
						</div>


						{leaderData && leaderData.wallet == blockchain.account  ? (
							<></>
						):(
							<div className="col-12 text-center mt-3">
								<button className="btn custom-btn" onClick={(e) => {
									setSelectedLeader(leaderData.referrer);
									const element = document.getElementById('teamleader');
									element?.scrollIntoView({
									  behavior: 'smooth'
									}); 
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('back-btn')}</strong></h5>
								</button>
							</div>
						)}


						<div className="col-12 text-center mt-5">
							<h3 className="brand-blue">{t('team-title3')}</h3>
							{/* <p className="text-white">共{userDirectReferrals.length}位成员</p> */}
						</div>


						
							{userDirectReferrals && userDirectReferrals.length > 0 ? (
								<>
									{userDirectReferrals && userDirectReferrals.map((teamUser, index) => (
										<div className="col-12 text-center my-3" key={index}>
											<button className="btn p-0" onClick={(e) => {
												getUserDirectReferrals(teamUser);
												const element = document.getElementById('teamleader');
												element?.scrollIntoView({
												  behavior: 'smooth'
												}); 
											}}>
												<div className="data-container">
													<img className="w-100" src={BigDataBox}></img>
													<div className="data-text text-left">
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('wallet-address')}：</strong><span className="text-white mt-1 mb-0" style={{wordBreak:"break-all"}}>{"0x..."+teamUser.wallet.substring(36)}</span></p>
														{teamUser.rank == 0 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">{t('team-rank0')}</span></strong></p> : <></>}
														{teamUser.rank == 1 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">MINER</span></strong></p> : <></>}
														{teamUser.rank == 2 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P1</span></strong></p> : <></>}
														{teamUser.rank == 3 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P2</span></strong></p> : <></>}
														{teamUser.rank == 4 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P3</span></strong></p> : <></>}
														{teamUser.rank == 5 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P4</span></strong></p> : <></>}
														{teamUser.rank == 6 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P5</span></strong></p> : <></>}
														{teamUser.rank == 7 ? <p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('level')}: <span className="text-white mt-1 mb-0">P6</span></strong></p> : <></>}
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('personal-mining')}: <span className="brand-green mt-1 mb-0" >{parseFloat(teamUser.total_deposit).toFixed(2)} USDT</span></strong></p>
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('team-p-total')}: <span className="brand-green mt-1 mb-0" >{parseFloat(teamUser.total_team_sales).toFixed(2)} USDT</span></strong></p>
													</div>
												</div>
											</button>
										</div>
									))}
								</>
							):(
								<div className="col-12 text-center">
									<div className="data-box mt-2">
										<p className="text-white mb-0">{t('no-referral')}</p>
									</div>
								</div>
							)}


					</div>
				</div>
			</div>
		</>
	);

};

export default TeamPage;