import { useState } from 'react';

export const useSignup = () => {
    const [registering, setRegistering] = useState(false);
    const [registerFeedback, setRegisterFeedback] = useState("");
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const walletSignup = async (wallet, referrer) => {
        setRegisterFeedback("注册中...");
        setRegistering(true);

        const response = await fetch('https://api.ridechain.io/user/register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                wallet,
                referrer,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log("Register failed:", json.error);
            setRegisterFeedback("注册失败，请稍后再尝试");
            setRegistering(false);
        }
        if (response.ok) {
            console.log("Register success.");
            setRegisterSuccess(true);
            setRegisterFeedback("注册成功，正在登入DApp");
            setRegistering(false);
        }

    }

    return { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess };
}