import Polyglot from 'node-polyglot';
import en from './locales/en.json';
import zh from './locales/zh.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import vi from './locales/vi.json';
import th from './locales/th.json';
import id from './locales/id.json';

const languages = {
  en,
  zh,
  ja,
  ko,
  vi,
  th,
  id
};

let polyglot = new Polyglot({ phrases: languages['zh'], locale: 'zh' });

export const setLanguage = (language) => {
  if (languages[language]) {
    polyglot = new Polyglot({ phrases: languages[language], locale: language });
  } else {
    console.warn(`Language ${language} not found, falling back to Chinese.`);
    polyglot = new Polyglot({ phrases: languages['zh'], locale: 'zh' });
  }
};

export const t = (key) => polyglot.t(key);

export default polyglot;
