import { useState } from 'react';

export const useOrder = () => {
    const [isOrdering, setIsOrdering] = useState(null);
    const [orderSuccess, setOrderSuccess] = useState(null);

    const createSellOrder = async (curUserID, userWallet, sellAmount) => {
        setIsOrdering(true);

        const response = await fetch('https://api.ridechain.io/order/create-sell-order', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                seller_wallet: userWallet,
                rc_amount: sellAmount,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setTimeout(() => setIsOrdering(false), 3000);
        }
        if (response.ok) {
            setOrderSuccess(true);
            setTimeout(() => setIsOrdering(false), 3000);
        }
    }

    return { isOrdering, setIsOrdering, orderSuccess, setOrderSuccess, createSellOrder };
}